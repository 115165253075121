import { useState, useEffect } from "preact/hooks";


interface JWTPayload {
  exp?: number; // expiration time
  [key: string]: unknown;
}

interface JWTHeader {
  alg: string;
  typ: string;
}

interface AnalyzerJWTProps {
  token: string;
}

export default function AnalyzerJWT({ token }: AnalyzerJWTProps) {
  const [header, setHeader] = useState<JWTHeader | null>(null);
  const [payload, setPayload] = useState<JWTPayload | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [_remainingTime, setRemainingTime] = useState<string | null>(null);

  const analyzeToken = (token: string) => {
    try {
      const parts = token.split(".");
      if (parts.length !== 3) {
        throw new Error("Invalid token format");
      }

      const decodedHeader = JSON.parse(atob(parts[0]));
      setHeader(decodedHeader);

      const decodedPayload = JSON.parse(atob(parts[1]));
      setPayload(decodedPayload);

      setSignature(parts[2]);

      if (decodedPayload.exp) {
        startCountdown(decodedPayload.exp);
      } else {
        setRemainingTime(null);
      }

      setError(null);
    } catch (err) {
      console.error("Error decoding token:", err);
      setError("Invalid token format or unable to decode.");
      setHeader(null);
      setPayload(null);
      setSignature(null);
      setRemainingTime(null);
    }
  };

  useEffect(() => {
    if (token) {
      analyzeToken(token);
    }
  }, [token]);

  const startCountdown = (exp: number) => {
    const updateRemainingTime = () => {
      const now = Math.floor(Date.now() / 1000);
      const timeLeft = exp - now;

      if (timeLeft <= 0) {
        setRemainingTime("Oops! This token has expired. You're out of time!");
        return;
      }

      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      const seconds = timeLeft % 60;

      setRemainingTime(
        `${hours}h ${minutes}m ${seconds}s until the token explodes 💣!`
      );
    };

    updateRemainingTime();
    const intervalId = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(intervalId);
  };

  return (
    <div class="jwt-analyzer p-4">
      <div class="mt-4">
        <h2>JWT Analyzer</h2>

        {error && <p style={{ color: "red" }}>{error}</p>}

        {header && payload && signature && (
          <div>
            <div class="p-4 border rounded bg-gray-50 mb-4">
              <h3>HEADER: ALGORITHM & TOKEN TYPE</h3>
              <pre>{JSON.stringify(header, null, 2)}</pre>
            </div>

            <div class="p-4 border rounded bg-gray-50 mb-4">
              <h4>PAYLOAD: DATA</h4>
              <pre>{JSON.stringify(payload, null, 2)}</pre>
            </div>

            <div class="p-4 border rounded bg-gray-50 mb-4">
              <h4>SIGNATURE</h4>
              <pre class="break-all">{signature}</pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
